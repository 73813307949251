export default [
  {
    name: "Jason",
    quote: `Robert is a lifetime learner and polymath. His vast interests allow him to approach problems with creativity that yields solutions beyond his years of professional experience.`
  },
  {
    name: "Eric",
    quote: `Robert's energy and enthusiasm are contagious and contributed a great deal to the culture and positive morale of our team. As a professional, he relentlessly pursues growth and takes all feedback seriously, as a chance to make himself better.`
  },
  {
    name: "Hunter",
    quote: `Robert is smart, driven, and a defining piece of his companies culture and morale. If I was starting a company tomorrow, Robert would be one of the first people I called to join me.`
  },
  {
    name: "Blackwood",
    quote: `TL;DR - Robert's an intelligent, charismatic, and driven engineer that I would work with again in a heartbeat.`
  }
];
