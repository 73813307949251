import React, { Component } from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
import SEO from "../components/SEO";
import config from "../../data/SiteConfig";
import PostListing from "../components/PostListing";
import quotes from "../../data/quotes";
import GitHubButton from "react-github-btn";
import myImage from "../images/Robert_900x900.png";

export default class Index extends Component {
  render() {
    const { data } = this.props;

    const latestPostEdges = data.latest.edges;
    //const popularPostEdges = data.popular.edges;

    return (
      <Layout>
        <Helmet title={`Hello World!`} />
        <SEO />
        <div className="container">
          <div className="lead">
            <div className="elevator">
              <h1>{`Hi, I'm Robert`}</h1>
              <p>
                This is where my thoughts come to live. I'm a software engineer
                by day and a creative junkie by night. Feel free to dive into my
                brain and read my <Link to="/blog">random thoughts</Link> on
                life.
              </p>
              <div className="social-buttons">
                <a
                  className="patreon"
                  href="https://www.patreon.com/rllivingston"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Patreon
                </a>
                <div>
                  <a
                    className="twitter-follow-button"
                    href="https://twitter.com/RLLivingston"
                    data-size="large"
                    data-show-screen-name="false"
                  >
                    Follow @RLLivingston
                  </a>
                </div>
              </div>
            </div>
            <div className="newsletter-section">
              <img
                src={myImage}
                className="newsletter-avatar"
                alt="Robert Livingston"
              />
              <div>
                <h3>Email Newsletter</h3>
                <p>
                  Get updated when I put out new content or make pieces of art
                  available for sale!
                </p>
                <a className="button" href="/newsletter">
                  Subscribe
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container front-page">
          <section className="section">
            <h2>
              Latest Blog Posts
              <Link to="/blog" className="view-all">
                View all
              </Link>
            </h2>
            <PostListing simple postEdges={latestPostEdges} />
          </section>

          {/* <section className="section">
            <h2>
              Most Popular
              <Link to="/categories/popular" className="view-all">
                View all
              </Link>
            </h2>
            <PostListing simple postEdges={popularPostEdges} />
          </section> */}

          {/* <section className="section">
            <h2>Open Source Projects</h2>
            <ProjectListing projects={projects} />
          </section> */}

          {/* <section className="section">
            <h2>Interviews</h2>
            <SimpleListing simple data={podcasts} />
          </section> */}

          {/* <section className="section">
            <h2>Talks</h2>
            <SimpleListing simple data={speaking} />
          </section> */}

          <section className="section">
            <h2>{`Other People's Opinions`}</h2>
            <div className="quotations">
              {quotes.map(quote => (
                <blockquote className="quotation" key={quote.name}>
                  <p>{quote.quote}</p>
                  <cite>— {quote.name}</cite>
                </blockquote>
              ))}
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    latest: allMarkdownRemark(
      limit: 6
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date
            template
          }
        }
      }
    }
    popular: allMarkdownRemark(
      limit: 7
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { categories: { eq: "Popular" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date
            template
          }
        }
      }
    }
  }
`;
